import styled from 'styled-components';

const palette = {
  mainColor: '#808080',
  labelColor: 'rgba(0, 0, 0, 0.40)',
  borderColor: '#ced4da',
  subColor: '#d8d8d8',
  spanColor: '#8D8B8B',
};

export const FormInputLabel = styled.label`
  color: ${palette.labelColor};
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: -10px;
  transition: 300ms ease all;
  padding: 0px 5px;
  background-color: white;
`;

export const FormInputBox = styled.input`
  background: none;
  background-color: white;
  padding: 0.8rem;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0.375rem !important;
  border: 1px solid ${palette.borderColor};

  &::placeholder {
    color: ${palette.subColor};
  }

  &:focus {
    border-color: #af9be2;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(95, 54, 196, 0.25);
  }
`;

export const FormInputHelperText = styled.span`
  position: absolute;
  right: 0;
  color: ${palette.spanColor};
  font-size: 12px;
`;

export const HelperText = styled.span`
  font-size: 0.7rem;
`;

import { useQuery } from 'react-query';
import { getOrderStatus } from '../consumer';

function useGetOrderByConsumer({ idOrder, signature }) {
  return useQuery('getOrderByConsumer', () =>
    getOrderStatus({ idOrder, signature })
  );
}

export default useGetOrderByConsumer;

import axios from 'axios';

// Creates the instance of an axios object with variant baseURL
const createAPI = (payload) => {
  const api = axios.create({
    baseURL: payload,
    //3 min timeout
    timeout: 60000 * 3,

    //follow up to 10 HTTP 3xx redirects
    maxRedirects: 10,

    //cap the maximum content length we'll accept to 50MBs, just in case
    maxContentLength: 50 * 1000 * 1000,
  });

  return api;
};

export default createAPI;

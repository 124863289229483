const dateParser = (date) => {
  const newDate = new Date(date);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();

  return (
    newDate.getFullYear() +
    "-" +
    `${month < 10 ? `0${month}` : month}` +
    "-" +
    `${day < 10 ? `0${day}` : day}` +
    " " +
    `${hours < 10 ? `0${hours}` : hours}` +
    ":" +
    `${minutes < 10 ? `0${minutes}` : minutes}`
  );
};

export default dateParser;

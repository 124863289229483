import { useQuery } from 'react-query';
import { getCities } from '../consumer';

function useGetCities({ idOrder, idEcommerce, signature }) {
  return useQuery(
    ['getCities', idOrder, idEcommerce],
    () => getCities({ idOrder, idEcommerce, signature }),
    {
      enabled: !!idEcommerce && !!idOrder && !!signature,
    }
  );
}

export default useGetCities;

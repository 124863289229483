import { HELP_ME_URL } from '../../config/consts/common';
import ConsumerLayout from '../common/layouts/ConsumerLayout';

function CityUpdateSuccess() {
  return (
    <ConsumerLayout>
      <section className="d-flex flex-column justify-content-center align-items-center h-75">
        <p className="text-secondary fw-bold fs-2 text-center mb-3">
          ¡Muchas gracias por la información!
        </p>

        <p className="fw-normal text-secondary text-center mx-5 px-4">
          Acabamos de actualizar tu pedido 😉 Tu pedido será entregado en la
          dirección y comuna indicada. ¡Que estés muy bien!
        </p>
      </section>

      <footer className="d-flex flex-column justify-content-center align-items-center">
        <p className="fw-normal text-secondary fs-6 text-center mx-5 px-5 mb-3">
          Si tienes dudas o problemas comunícate con nosotros
        </p>
        <a
          className="btn btn-primary mb-5 px-5 fw-normal"
          href={HELP_ME_URL}
          target="_blank"
          rel="noreferrer"
        >
          Quiero Ayuda
        </a>
      </footer>
    </ConsumerLayout>
  );
}

export default CityUpdateSuccess;

export const ORDER_STATUSES = {
  TO_PICKUP_FROM_ECOMMERCE: 1,
  TO_RECEIVE_FROM_ECOMMERCE: 2,
  TO_ASSEMBLE: 3,
  IN_ROUTE_TO_PICKUP_POINT: 4,
  ASSEMBLED: 5,
  READY_TO_BE_DELIVERED: 6,
  IN_ROUTE_TO_CONSUMER: 7,
  DELIVERED_TO_CONSUMER: 8,
  TO_RETURN_TO_PICKUP_POINT: 9,
  TO_PICKUP_FROM_CONSUMER: 10,
  IN_ROUTE_TO_WARECLOUD: 11,
  TO_RETURN_TO_ECOMMERCE: 12,
  RETURNED_TO_ECOMMERCE: 13,
  RECEIVED: 14,
  TO_DISASSEMBLE: 15,
  DISASSEMBLED: 16,
  IN_ROUTE_TO_CONSUMER_EXTERNAL_COURIER: 17,
  RETURNED_TO_WARECLOUD_BY_EXTERNAL_COURIER: 18,
  LOST_BY_EXTERNAL_COURIER: 19,
  DELIVERED_TO_CONSUMER_BY_EXTERNAL_COURIER: 20,
  DELIVERED_TO_CONSUMER_WITH_ERROR: 21,
  DELIVERED_TO_CONSUMER_WITH_REPLACEMENT_SERVICE: 22,
  TO_RETURN_TO_NO_PICKUP_POINT: 23,
  PAUSED: 97,
  PENDING_INFORMATION: 98,
  CANCELLED: 99,
};

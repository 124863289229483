import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import NotFoundPage from './features/common/NotFoundPage';
import RatingsPage from './features/ratings';
import ConsumerTracking from './features/tracking';
import ConsumerUpdateHub from './features/city-update';
import CityUpdateSuccess from './features/city-update/CityUpdateSuccess';
import ErrorPage from './features/common/ErrorPage';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route
          path="/ratings"
          exact
          component={RatingsPage}
          module="users-rating"
        />
        <Route
          path="/tracking"
          exact
          component={ConsumerTracking}
          module="users-tracking"
        />
        <Route
          path="/city-correction"
          exact
          component={ConsumerUpdateHub}
          module="update-city"
        />
        <Route
          path="/city-update-succesfull"
          exact
          component={CityUpdateSuccess}
          module="city-update-success"
        />
        <Route path="/error" exact component={ErrorPage} module="error" />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

import createAuthorizedAPI from "./index";

const baseURL = process.env.REACT_APP_ORDERS_API_URL;

const { get } = createAuthorizedAPI(baseURL);

const ordersAPI = {
  getOrderStatus: ({ idOrder, email }) => {
    return get(`consumers/orders/${idOrder}/events?email=${email}`);
  },
};

export const { getOrderStatus } = ordersAPI;

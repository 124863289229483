import { useLocation } from 'react-router-dom';
import NotFound from '../common/NotFoundPage';
import CityUpdateConfirmation from './CityUpdateConfirmation';

function ConsumerUpdateHub() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const hash = decodeURIComponent(searchParams.get('data'));
  const idOrder = decodeURIComponent(searchParams.get('idOrder'));

  if (!hash && !idOrder) return <NotFound />;

  return (
    <CityUpdateConfirmation
      idOrder={parseInt(idOrder, 10)}
      validationHash={hash}
    />
  );
}

export default ConsumerUpdateHub;

import React from "react";

import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const SpinnerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PageSpinner = () => {
  return (
    <SpinnerContainer>
      <Spinner animation="grow" variant="primary" role="status" />
      <strong>Cargando...</strong>
    </SpinnerContainer>
  );
};

export default PageSpinner;

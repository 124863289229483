import createAPI from "./index";

const baseURL = process.env.REACT_APP_EXPERIMENTS_API_URL;

const api = createAPI(baseURL);

const experimentsAPI = {
  submitUserRating: (payload) => {
    return api.post("/rating/orders/", payload);
  },
};

export const { submitUserRating } = experimentsAPI;

import Select from 'react-select';
import styled from 'styled-components';

const HelperText = styled.span`
  font-size: 0.7rem;
`;
function FormAutocomplete({ hasError, errorLabel, ...props }) {
  return (
    <div className="position-relative mx-4 my-2">
      <Select {...props} styles={colourStyles} />
      {hasError && (
        <HelperText className="form-text text-danger">{errorLabel}</HelperText>
      )}
    </div>
  );
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '0.375rem',
    padding: '0.3rem',
    textAlign: 'center',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    borderColor: isFocused ? '#af9be2' : '',
    backgroundColor: isSelected ? '#5f36c4' : isFocused ? '#af9be2' : undefined,
  }),
  input: (styles, { isFocused }) => ({
    ...styles,
    borderColor: isFocused ? '#af9be2' : '',
  }),
};

export default FormAutocomplete;

// Core
import React from 'react';

// Dependencies
import styled from 'styled-components';

// Components
import notFoundImage from '../../assets/img/404.png';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
`;

// Component displayed when user accesses an unexisting route
const NotFound = () => (
  <PageContainer>
    <h3 className="text-center m-3">La página buscada no existe</h3>
    <img src={notFoundImage} alt="404 NotFound" className="img-fluid" />
  </PageContainer>
);

export default NotFound;

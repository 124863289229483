import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { confirmCity } from '../consumer';

function useConfirmCity() {
  const history = useHistory();
  return useMutation(
    'confirmCity',
    (data) => {
      return confirmCity(data);
    },
    {
      onSuccess: () => {
        history.replace('/city-update-succesfull');
      },
      onError: () => {
        history.replace('/error');
      },
    }
  );
}

export default useConfirmCity;

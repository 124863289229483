import { Button } from 'react-bootstrap';

function LoadingButton({ label, isLoading, submittingLabel, ...props }) {
  return (
    <Button {...props}>
      {isLoading ? (
        <>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>{' '}
          <span>{submittingLabel}</span>
        </>
      ) : (
        label
      )}
    </Button>
  );
}

export default LoadingButton;

import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const LayoutWarpper = styled.section`
  min-height: 100vh;
`;

export const LayoutContainer = styled(Col)`
  min-height: 100vh;
`;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {},
});

// eslint-disable-next-line
export const {} = trackingSlice.actions;

export default trackingSlice.reducer;

import { combineReducers } from "redux";

import trackingReducer from "../features/tracking/trackingSlice";

const combinedReducers = combineReducers({
  tracking: trackingReducer,
});

const rootReducer = (state, action) => {
  // When dispatches the logout action through auth reducer, reset state
  if (action.type === "auth/logout") {
    state = undefined;
  }

  return combinedReducers(state, action);
};

export default rootReducer;

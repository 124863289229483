import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import useConfirmCity from '../../api/hooks/useConfirmCity';
import useGetCities from '../../api/hooks/useGetCities';
import FormInput from '../common/form-input/form-input';
import LoadingButton from '../common/LoadingButton';

import FormAutocomplete from '../common/form-autocomplete/form-autocomplete';

const defaultFormFields = {
  city: null,
  address: '',
  address_detail: '',
  address_extra_info: '',
};

const getTransformCityData = (cities) => {
  const asignedCities = cities?.map((city) => ({
    value: city.id_city,
    label: city.city_name,
  }));

  return asignedCities;
};

function UpdateForm({ orderData, signature }) {
  const { data, isLoading, isError } = useGetCities({
    idOrder: orderData.id_order,
    idEcommerce: orderData.ecommerce?.id_ecommerce,
    signature,
  });
  const { isLoading: loadingUpdateCity, mutateAsync } = useConfirmCity();

  const { handleSubmit, setValue, control } = useForm({
    defaultValues: defaultFormFields,
  });

  const onHandleSubmit = async (data) => {
    const { city, ...rest } = data;

    const payload = {
      id_city: city.value,
      ...rest,
    };

    await mutateAsync({
      idOrder: orderData.id_order,
      email: orderData.email,
      payload,
      signature,
    });
  };

  useEffect(() => {
    const { id_city, address, address_detail, address_extra_info } = orderData;
    const cities = getTransformCityData(data?.data?.data.cities);

    const city = cities?.find((city) => city.value === id_city);

    setValue('city', city);
    setValue('address', address);
    setValue('address_detail', address_detail);
    setValue('address_extra_info', address_extra_info);
  }, [data?.data?.data.cities, isLoading, orderData, setValue]);

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <header className="mb-3">
        <p className="text-center text-secondary fw-normal px-5">
          Elige la comuna de tu domicilio y confirma tu dirección para poder
          hacer entrega de tu pedido
        </p>
      </header>

      <section>
        <Controller
          name="city"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'El campo comuna es obligatorio',
            },
          }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState,
          }) => {
            return (
              <FormAutocomplete
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                isLoading={isLoading}
                disabled={isError}
                options={getTransformCityData(data?.data?.data.cities)}
                placeholder="Selecciona tu comuna"
                hasError={fieldState.error}
                errorLabel={fieldState.error?.message}
              />
            );
          }}
        />

        <Controller
          name="address"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'El campo dirección es obligatorio',
            },
          }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState,
          }) => {
            return (
              <FormInput
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                label="Dirección"
                type="text"
                placeholder="Av los condes 7701"
                hasError={fieldState.error}
                errorLabel={fieldState.error?.message}
              />
            );
          }}
        />

        <Controller
          name="address_detail"
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <FormInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              inputRef={ref}
              label="Dpto/Bloque/Oficina"
              type="text"
              placeholder="Depto 205"
              isOptional
            />
          )}
        />

        <Controller
          name="address_extra_info"
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <FormInput
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              inputRef={ref}
              label="Comentario"
              type="text"
              placeholder="Recibe Rocío"
              isOptional
            />
          )}
        />
      </section>

      <footer className="d-grid mx-5 px-5 mb-3">
        <LoadingButton
          type="submit"
          label="Confirmar"
          submittingLabel="Procesando..."
          disabled={isLoading || loadingUpdateCity}
          isLoading={loadingUpdateCity}
        />
      </footer>
    </form>
  );
}

export default UpdateForm;

import createAuthorizedAPI from './index';

const baseURL = process.env.REACT_APP_CONSUMER_API_URL;

const { get, put } = createAuthorizedAPI(baseURL);

const consumerAPI = {
  getOrderStatus: ({ idOrder, signature }) => {
    return get(`orders/${idOrder}/details`, {
      headers: {
        'wcd-signature': `${signature}`,
      },
    });
  },

  getCities: ({ idOrder, idEcommerce, signature }) => {
    return get(`/orders/${idOrder}/ecommerces/${idEcommerce}/assignedCities`, {
      headers: {
        'wcd-signature': `${signature}`,
      },
    });
  },

  confirmCity: ({ idOrder, email, payload, signature }) => {
    return put(`/orders/${idOrder}/pendingInfo?email=${email}`, payload, {
      headers: { 'wcd-signature': `${signature}` },
    });
  },
};

export const { getOrderStatus, getCities, confirmCity } = consumerAPI;

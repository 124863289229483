import { Fragment } from 'react';
import Tracking from './Tracking';

import toAssembleIcon from '../../assets/img/tracking/to-assemble.png';
import assembledIcon from '../../assets/img/tracking/assembled.png';
import toDeliver from '../../assets/img/tracking/to-deliver.png';
import delivered from '../../assets/img/tracking/delivered.png';

import { ReactComponent as Separator } from '../../assets/img/tracking/separator.svg';
import styled from 'styled-components';

const TRACKING_OPTIONS = [
  { id: 1, img: toAssembleIcon, label: 'Procesando tu compra', isActive: true },
  { id: 2, img: assembledIcon, label: 'Pedido armado', isActive: false },
  { id: 3, img: toDeliver, label: 'Pedido en camino', isActive: false },
  { id: 4, img: delivered, label: 'Pedido entregado', isActive: false },
];

const Paragraph = styled.p`
  font-size: 1.1rem;
`;

function TrackingList() {
  return (
    <section className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-2 mt-md-4 gap-1 px-lg-5">
      {TRACKING_OPTIONS.map(({ id, img, label, isActive }, index, array) => (
        <Fragment key={id}>
          <div className="w-25">
            <Tracking src={img} alt={label} isActive={isActive} />
            <Paragraph className="text-center fw-bold fs-6 text-primary mt-2">
              {label}
            </Paragraph>
          </div>
          {index < array.length - 1 && <Separator className="mb-md-5" />}
        </Fragment>
      ))}
    </section>
  );
}

export default TrackingList;

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router';

import { submitUserRating } from '../../api/experiments';

import logo from '../../assets/img/logo.svg';

const BASE_URL = 'https://www.wareclouds.com?utm_medium=consumer_app';

const LayoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #5f36c4;

  h1 {
    font-weight: 800;
  }

  p {
    font-weight: 400;
  }
`;

// TODO move this constant to i18n
const internationalizationObj = {
  cl: {
    message_data_exist: 'Tu calificación ya se encuentra registrada.',
    message_error: 'Ha ocurrido un problema. Vuelve a intentarlo más tarde.',
    title: '¡Gracias por darnos tu opinión!',
    paragraph1:
      'Utilizaremos tu valoración para ir mejorando la calidad de nuestros servicios. 💜',
    paragraph2: 'Hasta la próxima.',
    buttonLabel: 'Visita Wareclouds',
  },
  'br-b2b': {
    message_data_exist: 'Sua qualificação já está registrada.',
    message_error: 'Ocorreu um problema. Tente novamente mais tarde.',
    title: '¡Obrigado por seu feedback!',
    paragraph1:
      'Usaremos seu avaliação para melhorar a qualidade de nossos serviços. 💜',
    paragraph2: 'Até a próxima',
    buttonLabel: 'Visita Wareclouds',
  },
};
const RatingsPage = () => {
  const location = useLocation();
  const [error, setError] = useState({ color: '', message: '' });
  const [context, setContext] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const payload = {
      rating_value: parseInt(searchParams.get('rating'), 10),
      id_order: parseInt(searchParams.get('order'), 10),
      id_context: 'cl',
    };
    const contextParam = searchParams.get('context');
    if (contextParam) {
      payload.id_context = contextParam;
    }

    setContext(payload.id_context);

    const submitRating = async () => {
      try {
        await submitUserRating(payload);
      } catch (err) {
        const { data: errorType } = err.response.data;
        if (errorType === 'DATA_EXISTS') {
          setError({
            color: 'success',
            message: internationalizationObj[context].message_data_exist,
          });
        } else {
          setError({
            color: 'danger',
            message: internationalizationObj[context].message_error,
          });
        }
      }
    };
    if (context) {
      submitRating();
    }
  }, [location, context]);

  return (
    <LayoutWrapper>
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <Card>
              <Card.Body className="mx-1 my-4">
                <Row>
                  <Col className="text-center">
                    <img src={logo} alt="logo" className="w-50" />
                    <h1 className="my-4">
                      {internationalizationObj[context]?.title}
                    </h1>
                    <p>{`${internationalizationObj[context]?.paragraph1}`}</p>
                    <p className="fw-bold my-3">
                      {internationalizationObj[context]?.paragraph2}
                    </p>
                    <div className="d-grid mx-4 d-md-flex justify-content-md-center mx-md-0">
                      <a
                        className="btn btn-primary text-uppercase px-md-5"
                        size="large"
                        href={BASE_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {internationalizationObj[context]?.buttonLabel}
                      </a>
                    </div>

                    {error.message && (
                      <p className={`text-${error.color} mt-3`}>
                        {error.message}
                      </p>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </LayoutWrapper>
  );
};

export default RatingsPage;

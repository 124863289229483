import ConsumerLayout from '../common/layouts/ConsumerLayout';
import TrackingList from './TrackingList';
import CityUpdateForm from './CityUpdateForm';

import useGetOrderByConsumer from '../../api/hooks/useGetOrderByConsumer';
import PageSpinner from '../common/PageSpinner';

import { ORDER_STATUSES } from '../../config/consts/orders';
import CityUpdateFeedback from './CityUpdateFeedback';
import ErrorPage from '../common/ErrorPage';

function ConsumerUpdateInformation({ idOrder, validationHash }) {
  const { isLoading, isError, data } = useGetOrderByConsumer({
    idOrder,
    signature: validationHash,
  });

  if (isLoading) return <PageSpinner />;

  if (isError) return <ErrorPage />;

  if (data.data.data.id_status_order !== ORDER_STATUSES.PENDING_INFORMATION)
    return <CityUpdateFeedback orderData={data.data?.data} />;

  return (
    <ConsumerLayout>
      <TrackingList />

      <section className="update-form mt-5">
        <CityUpdateForm
          orderData={data.data?.data}
          signature={validationHash}
        />
      </section>
    </ConsumerLayout>
  );
}

export default ConsumerUpdateInformation;

import {
  FormInputBox,
  FormInputLabel,
  FormInputHelperText,
  HelperText,
} from './form-input.styles.js';

const FormInput = ({
  label,
  isOptional,
  errorLabel,
  hasError,
  ...otherProps
}) => {
  return (
    <div className="position-relative mx-4 my-4 py-2">
      <FormInputBox {...otherProps} />
      {label && <FormInputLabel>{label}</FormInputLabel>}
      {isOptional && <FormInputHelperText>*Opcional</FormInputHelperText>}
      {hasError && (
        <HelperText className="form-text text-danger">{errorLabel}</HelperText>
      )}
    </div>
  );
};

export default FormInput;

import { Row } from 'react-bootstrap';

import logo from '../../../assets/img/logo.svg';
import { LayoutWarpper, LayoutContainer } from './ConsumerLayout.styles';

function ConsumerLayout({ children }) {
  return (
    <LayoutWarpper className="bg-primary">
      <Row className="g-0">
        <LayoutContainer
          xs
          md={7}
          xl={5}
          className="d-flex flex-column mx-auto bg-white px-1 px-md-4"
          style={{ minHeight: '100vh' }}
        >
          <figure className="d-flex justify-content-center p-4">
            <img src={logo} alt="logo" className="w-75" />
          </figure>

          {children}
        </LayoutContainer>
      </Row>
    </LayoutWarpper>
  );
}

export default ConsumerLayout;

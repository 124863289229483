function Tracking({ src, alt, isActive }) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div
        className={`rounded-pill d-flex justify-content-center p-3 ${
          isActive ? 'bg-primary' : 'bg-secondary'
        }`}
      >
        <img
          src={src}
          alt={alt}
          height={60}
          width={60}
          className="align-self-center"
        />
      </div>
    </div>
  );
}

export default Tracking;

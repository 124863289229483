import { HELP_ME_URL } from '../../config/consts/common';
import ConsumerLayout from '../common/layouts/ConsumerLayout';

function CityUpdateFeedback({ orderData }) {
  const { address, city_name, address_detail, address_extra_info } = orderData;

  return (
    <ConsumerLayout>
      <section className="d-flex flex-column justify-content-center align-items-center h-75">
        <p className="text-secondary fw-bold fs-2 text-center mb-3 px-4">
          ¡Ya recibimos información de tu dirección!
        </p>

        <p className="fw-normal text-secondary text-center mx-5 px-4">
          Tu pedido ya fue actualizado 😉 La comuna y dirección registrada de tu
          pedido fue la siguiente:
        </p>

        <ul className="mt-3">
          <li>
            <p className="fw-normal">
              <span className="fw-bold">Dirección:</span> {address}
            </p>
          </li>
          <li>
            <p className="fw-normal">
              <span className="fw-bold">Comuna:</span> {city_name}
            </p>
          </li>
          <li>
            <p className="fw-normal">
              <span className="fw-bold">Detalle:</span> {address_detail || '-'}
            </p>
          </li>
          <li>
            <p className="fw-normal">
              <span className="fw-bold">Comentario:</span>{' '}
              {address_extra_info || '-'}
            </p>
          </li>
        </ul>
      </section>

      <footer className="d-flex flex-column justify-content-center align-items-center">
        <p className="fw-normal text-secondary fs-6 text-center mx-5 px-5 mb-3">
          Si tienes dudas o problemas comunícate con nosotros
        </p>
        <a
          className="btn btn-primary mb-5 px-5 fw-normal"
          href={HELP_ME_URL}
          target="_blank"
          rel="noreferrer"
        >
          Quiero Ayuda
        </a>
      </footer>
    </ConsumerLayout>
  );
}

export default CityUpdateFeedback;
